<template>
  <div>
      <v-main>
        <v-container fluid>
            <v-card>
              <v-card-title>
                <v-col cols="12" md="6" align="left" class="pa-0">
                  Data Member K-BUFU
                </v-col>
                <v-col cols="12" md="6" class="pa-0">
                  <v-form @submit="submitSearch">
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Cari Nama Anggota"
                      @click:append="searchMember"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-form>
                </v-col>
              </v-card-title>
              <v-card-title class="mb-n5">
                <h1 class="subtitle-2 font-weight">
                    Anggota Terdaftar : {{memberAmount}}
                </h1>
                <v-spacer />
                <v-btn color="success" @click="dialogExport = true">Export excel</v-btn>
                <v-btn style="text-decoration:none; margin-left:10px" color="primary" @click="download('ktp', false)">
                  <span v-show="!loadKtp"><v-icon>mdi-download</v-icon> KTP</span>
                  <b-spinner v-show="loadKtp" small></b-spinner></v-btn>
                <v-btn style="text-decoration:none; margin-left:10px" color="error" @click="download('pas_foto', false)">
                  <span v-show="!loadFoto"><v-icon>mdi-download</v-icon> PAS FOTO</span>
                  <b-spinner v-show="loadFoto" small></b-spinner></v-btn>
              </v-card-title>
              <v-divider />
              <p class="text-center">
                STATUS ANGGOTA
              </p>
              <v-btn-toggle
                mandatory
              >
                <template v-for="(status, i) in listMemberStatus">
                  <v-btn :key="i" @click="fetchByStatus(status)">
                    {{status.statusName}}
                  </v-btn>
                </template>
              </v-btn-toggle>
              <v-data-table
                :headers="headers"
                :items="listMember"
                :options.sync="pagination"
                :loading=loading
                :footer-props="footerProps"
                :server-items-length="memberAmount"
              >
                <template v-slot:top>
                    <v-toolbar flat>
                        <!-- <v-spacer /> -->
                        <v-dialog
                          v-model="dialog"
                          max-width="600px"
                        >
                          <v-card>
                            <v-card-title>
                              <span class="headline">{{formTitle}}</span>
                            </v-card-title>

                            <v-card-text>
                              <v-container>
                                <v-row>
                                  <v-col
                                    cols="12"
                                    md="12"
                                  >
                                    <v-text-field
                                      v-model="detailItem.fullName"
                                      label="Nama Anggota"
                                      readonly
                                    ></v-text-field>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    md="12"
                                  >
                                    <v-text-field
                                      v-model="detailItem.identityNumber"
                                      :label="detailItem.identityType"
                                      readonly
                                    ></v-text-field>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    md="3"
                                  >
                                    <v-text-field
                                      v-model="detailItem.noAims"
                                      label="No Aims"
                                      readonly
                                    ></v-text-field>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    md="4"
                                  >
                                    <v-text-field
                                      v-model="detailItem.tanzeem"
                                      label="Badan"
                                      readonly
                                    ></v-text-field>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    md="5"
                                  >
                                    <v-text-field
                                      v-model="detailItem.branch"
                                      label="Cabang"
                                      readonly
                                    ></v-text-field>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    md="6"
                                  >
                                    <v-text-field
                                      v-model="detailItem.placeOfBirth"
                                      label="Tempat Lahir"
                                      readonly
                                    ></v-text-field>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    md="6"
                                  >
                                    <v-text-field
                                      v-model="detailItem.dateOfBirth"
                                      label="Tanggal Lahir"
                                      readonly
                                    ></v-text-field>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    md="12"
                                  >
                                    <v-text-field
                                      v-model="detailItem.gender"
                                      label="Jenis Kelamin"
                                      readonly
                                    ></v-text-field>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    md="8"
                                  >
                                    <v-text-field
                                      v-model="detailItem.email"
                                      label="Email"
                                      :readonly="!editedEmail"
                                      :append-icon="!editedEmail ? 'mdi-pencil' : 'mdi-send'"
                                      @click:append="showEdit"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    md="4"
                                  >
                                    <v-text-field
                                      v-model="detailItem.phone"
                                      label="No HP"
                                      readonly
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                                <v-divider />
                                <v-row>
                                    <v-col
                                        cols="12"
                                        md="12"
                                    >
                                        <v-card-subtitle>
                                            Alamat berdasarkan identitas
                                        </v-card-subtitle>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        md="6"
                                    >
                                        <v-text-field
                                        v-model="detailItem.address.provinsi"
                                        label="Provinsi"
                                        readonly
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        md="6"
                                    >
                                        <v-text-field
                                        v-model="detailItem.address.kabupaten"
                                        label="Kabupaten"
                                        readonly
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        md="6"
                                    >
                                        <v-text-field
                                        v-model="detailItem.address.kecamatan"
                                        label="Kecamatan"
                                        readonly
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        md="6"
                                    >
                                        <v-text-field
                                        v-model="detailItem.address.kelurahan"
                                        label="Kelurahan"
                                        readonly
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        md="1"
                                    >
                                        <v-text-field
                                        v-model="detailItem.address.rt"
                                        label="RT"
                                        readonly
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        md="1"
                                    >
                                        <v-text-field
                                        v-model="detailItem.address.rw"
                                        label="RW"
                                        readonly
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        md="10"
                                    >
                                        <v-text-field
                                        v-model="detailItem.address.address"
                                        label="Alamat"
                                        readonly
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-divider />
                                <v-row>
                                    <v-col
                                        cols="12"
                                        md="12"
                                    >
                                        <v-card-subtitle>
                                            Alamat berdasarkan domisili
                                        </v-card-subtitle>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        md="6"
                                    >
                                        <v-text-field
                                        v-model="detailItem.addressResidence.provinsi"
                                        label="Provinsi"
                                        readonly
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        md="6"
                                    >
                                        <v-text-field
                                        v-model="detailItem.addressResidence.kabupaten"
                                        label="Kabupaten"
                                        readonly
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        md="6"
                                    >
                                        <v-text-field
                                        v-model="detailItem.addressResidence.kecamatan"
                                        label="Kecamatan"
                                        readonly
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        md="6"
                                    >
                                        <v-text-field
                                        v-model="detailItem.addressResidence.kelurahan"
                                        label="Kelurahan"
                                        readonly
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        md="1"
                                    >
                                        <v-text-field
                                        v-model="detailItem.addressResidence.rt"
                                        label="RT"
                                        readonly
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        md="1"
                                    >
                                        <v-text-field
                                        v-model="detailItem.addressResidence.rw"
                                        label="RW"
                                        readonly
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        md="10"
                                    >
                                        <v-text-field
                                        v-model="detailItem.addressResidence.address"
                                        label="Alamat"
                                        readonly
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                              </v-container>
                            </v-card-text>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="success"
                                @click="download('kta', true)"
                              >
                                <span v-show="!loadKta">Generate KTA</span>
                                <b-spinner v-show="loadKta" small></b-spinner>
                              </v-btn>
                              <v-btn
                                color="primary"
                                @click="download('ktp', true)"
                              >
                                <span v-show="!loadKtp"><v-icon>mdi-download</v-icon> KTP</span>
                                <b-spinner v-show="loadKtp" small></b-spinner>
                              </v-btn>
                              <v-btn
                                color="error"
                                @click="download('pas_foto', true)"
                              >
                                <span v-show="!loadFoto"><v-icon>mdi-download</v-icon> PAS FOTO</span>
                                <b-spinner v-show="loadFoto" small></b-spinner>
                              </v-btn>
                              <v-btn
                                color="red darken-1"
                                text
                                @click="close"
                              >
                                Kembali
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
                <template v-slot:[`item.gender`]="{ item }">
                    {{ item.gender === 'L' ? 'Laki-Laki' : 'Perempuan' }}
                </template>
                <template v-slot:[`item.memberStatus`]="{ item }">
                <v-chip
                    :color="getColor(item.memberStatus)"
                    dark
                >
                    {{ item.memberStatus }}
                </v-chip>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                      <v-btn class="pa-0 ma-0" text style="text-decoration:none" color="warning" :href="exportMemberAPI+item.memberId" target="_blank">
                        <v-icon
                          small
                        >
                          mdi-export
                        </v-icon>
                      </v-btn>
                      <v-icon
                        small
                        class="mr-2"
                        @click="topup(item)"
                        color="primary"
                      >
                        mdi-book-plus-outline
                      </v-icon>
                      <v-icon
                        small
                        class="mr-2"
                        @click="detail(item)"
                        color="green"
                      >
                        mdi-eye-outline
                      </v-icon>
                      <v-icon
                        small
                        class="mr-2"
                        v-if="item.memberStatus === 'Candidate'"
                        @click="confirmDelete(item)"
                        color="error"
                      >
                        mdi-delete
                      </v-icon>
                </template>
              </v-data-table>
            </v-card>
            <v-dialog
                  v-model="dialogDel"
                  persistent
                  max-width="500"
                  >
                  <v-card>
                      <v-card-title class="headline">
                      Hapus anggota!
                      </v-card-title>
                      <v-card-text style="text-align:left">Konfirmasi hapus anggota an <code>{{confirmMemberName}}</code> sebagai kandidat koperasi ?</v-card-text>
                      <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                          color="red darken-1"
                          text
                          @click="dialogDel = false"
                      >
                          Batal
                      </v-btn>
                      <v-btn
                          color="green darken-1"
                          text
                          @click="deleteItem()"
                      >
                          <span v-show="!isLoad">Konfirmasi</span>
                          <b-spinner v-show="isLoad" small></b-spinner>
                      </v-btn>
                      </v-card-actions>
                  </v-card>
            </v-dialog>
            <v-dialog 
              v-model="dialogExport"
              max-width="450">
                <v-card class="pa-5">
                  <v-btn style="text-decoration:none" width="100" class="mr-1" color="success" :href="exportAPI+2" target="_blank">Active</v-btn>
                  <v-btn style="text-decoration:none" width="100" class="mr-1" color="primary" :href="exportAPI+3" target="_blank">InActive</v-btn>
                  <v-btn style="text-decoration:none" width="100" color="error" :href="exportAPI+4" target="_blank">Candidate</v-btn>
                </v-card>      
            </v-dialog>
            <v-snackbar
              v-model="snackbar"
              :timeout="timeout"
            >
              {{msgSnack}} 
            </v-snackbar>
        </v-container>
      </v-main>
    <OverlayProgress :overlay=$root.overlay :isError=$root.isError />
  </div>
  
</template>

<script>
import OverlayProgress from '@/components/OverlayProgress.vue'

export default {
  components: {
    OverlayProgress
  },
  data() {
    return {
        dialogExport: false,
        statusMember: null,
        editedEmail:false,
        pagination: {
          page: 1,
          itemsPerPage: 20,
        },
        footerProps: {
          itemsPerPageOptions: [20, 50, 100, -1],
          showFirstLastPage: true,
          showCurrentPage: true,
        },
        exportAPI: process.env.VUE_APP_API_BASE_URL+this.$root.api.get.exportExcel+"?status=",
        exportMemberAPI: process.env.VUE_APP_API_BASE_URL+this.$root.api.get.exportExcel+"?memberId=",
        loading: true,
        loadKtp: false,
        loadKta: false,
        loadFoto: false,
        dialog: false,
        memberAmount: 0,
        detailItem: {
            memberId: null,
            memberNo: null,
            noAims: null,
            fullName: null,
            tanzeem: null,
            branch: null,
            placeOfBirth: null,
            dateOfBirth: null,
            gender: null,
            email: null,
            phone: null,
            identityType: null,
            identityNumber: null,
            address: {
                provinsi: null,
                kabupaten: null,
                kecamatan: null,
                kelurahan: null,
                rt: null,
                rw: null,
                address: null,
            },
            addressResidence: {
                provinsi: null,
                kabupaten: null,
                kecamatan: null,
                kelurahan: null,
                rt: null,
                rw: null,
                address: null,
            },
            memberStatus: null
        },
        search: '',
        headers: [
          {
            text: 'No Anggota',
            align: 'start',
            sortable: false,
            value: 'memberNo',
          },
          { text: 'No Aims', sortable: false, value: 'noAims' },
          { text: 'Nama', sortable: false, value: 'fullName' },
          { text: 'Badan', sortable: false, value: 'tanzeem' },
          { text: 'Jenis Kelamin', sortable: false, value: 'gender' },
          { text: 'Status Anggota', sortable: false, align: 'center', value: 'memberStatus' },
          { text: '', value: 'actions', sortable: false },
        ],
        listMember: [],
        listMemberStatus: [],
        dialogDel: false,
        confirmMemberName: '',
        itemDel: null,
        isLoad: false,
        snackbar: false,
        timeout: 4000,
        msgSnack: ''
    }
  },
  computed:{
    formTitle(){
      return 'Detail Anggota'
    }
  },
  watch:{
    pagination:{
      handler(){
        this.fetchMembers()
      },
      deep:true
    }
  },
  methods:{
    async download(file, byId){
      var url = this.$root.api.get.download
      var name = file

      if(file === 'ktp'){
        url = url+'?type=ktp_foto'
        this.loadKtp=true
      }
      else if(file === 'pas_foto'){
        url = url+'?type=pas_foto'
        this.loadFoto=true
      }else if(file === 'kta'){
        url = url+'?type=kta_template'
        this.loadKta = true
      }

      if(byId) {
        url = url+'&memberId='+this.detailItem.memberId
        name = file+'_'+this.detailItem.fullName
      }


      await this.$root.api.axios(
      {
          method: 'get',
          url: url,
          responseType: 'arraybuffer',
      }).then((response) => {
        if (response.headers['content-type'] !== undefined) {
          var fileURL = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
          var fileLink = document.createElement('a');

          fileLink.href = fileURL;
          fileLink.setAttribute('download', 'KBUFU_'+name.toUpperCase()+'.zip');
          document.body.appendChild(fileLink);

          fileLink.click(); 
        }
      })
      .catch((err) => {
          this.$root.isError = true
          if(!navigator.onLine) this.$root.isError = err.message
      })
      if(!this.$root.isError) this.$root.overlay = false 
      this.loadKtp=false
      this.loadKta=false
      this.loadFoto=false
    },
    confirmDelete(item){
      this.dialogDel = true
      this.confirmMemberName = item.fullName
      this.itemDel = item
    },
    deleteItem(){
      this.isLoad = true

      this.$root.api.sentAPI(
        {
            method: 'delete',
            api: this.$root.api.del.member+'?memberId='+this.itemDel.memberId
        }).then((res) => {
            this.snackbar = true
            this.dialogDel = false
            this.isLoad = false
            this.msgSnack = res.status.message
            this.fetchMembers()
        })
        .catch((err) => {
            this.$root.isError = true
            if(!navigator.onLine) this.$root.isError = err.message
        })
    },
    submitSearch(evt){
      evt.preventDefault()
      this.fetchMembers()
      this.search = ''
    },
    searchMember(){
      this.fetchMembers()
      this.search = ''
    },
    showEdit(){
      if (this.editedEmail) {
        this.$root.api.sentAPI(
        {
            method: 'post',
            api: this.$root.api.update.memberEmail,
            form:{
              email: this.detailItem.email,
              memberId: this.detailItem.memberId
            }
        }).then(() => {
            this.editedEmail = false
        })
        .catch((err) => {
            this.$root.isError = true
            if(!navigator.onLine) this.$root.isError = err.message
        })
      }else{
        this.editedEmail = true
      }
    },
    detail(item){
        this.detailItem = Object.assign({}, item)
        this.detailItem.gender = this.detailItem.gender === 'L' ? 'Laki-Laki' : 'Perempuan' 
        this.dialog = true
    },
    topup(item){
      this.$router.push({path: '/manage/members/topup', query: {memberId: item.memberId, memberName: item.fullName}})
    },
    close () {
      this.dialog = false
    },
    getColor(status){
        if(status === 'Active')
            return 'green'
        else return 'red'
    },
    fetchByStatus(status){ 
      this.statusMember = status.idStatus
      this.fetchMembers()
    },
    async fetchMembers(){
      this.loading = true
      const {page, itemsPerPage} = this.pagination
      var url = this.$root.api.get.member
      if (itemsPerPage > -1) {
        url = this.$root.api.get.member+'?page='+(page-1)+'&size='+itemsPerPage
      }
      if (this.statusMember > 0) {
        if (itemsPerPage < 0) {
          url = url+'?idStatus='+this.statusMember
        }else{
          url = url+'&idStatus='+this.statusMember
        }
      }
      if (this.search !== '') {
        url = url+'&name='+this.search
      }
      await this.$root.api.sentAPI(
      {
          method: 'get',
          api: url
      }).then((res) => {
          this.memberAmount = res.data.count
          // this.footerProps.pagination.itemsLength = res.data.count
          this.listMember = res.data.list
      })
      .catch((err) => {
          this.$root.isError = true
          if(!navigator.onLine) this.$root.isError = err.message
      })
      if(!this.$root.isError) this.$root.overlay = false 
      this.loading = false
    },
    async fetchStatusMember(){
      this.loading = true
      await this.$root.api.sentAPI(
      {
          method: 'get',
          api: this.$root.api.get.status
      }).then((res) => {
          this.listMemberStatus.push({
              idStatus: 0,
              statusName: 'All'
          })
          for (let i = 0; i < res.data.memberStatus.length; i++) {
            this.listMemberStatus.push({
              idStatus: res.data.memberStatus[i].idStatus,
              statusName: res.data.memberStatus[i].statusName,
            })
          }
      })
      .catch((err) => {
          this.$root.isError = true
          if(!navigator.onLine) this.$root.isError = err.message
      })
      if(!this.$root.isError) this.$root.overlay = false 
    }
  },
  mounted() {
    this.fetchMembers()
    this.fetchStatusMember()
  }
}
</script>
<style scoped>
</style>
